import React, {Component} from 'react';
import DesktopContainer from "../../container/DesktopContainer";
import MobileContainer from "../../container/MobileContainer";
import {GoogleApiWrapper, InfoWindow, Map, Marker} from 'google-maps-react';
import styles from "./styles.module.css";

const mapStyles = {
    width: '100%',
    height: '100%',
};

function getPlace(placeId) {
    return {
        fields: ['place_id', 'plus_code', 'name', 'geometry', 'formatted_address', 'formatted_phone_number', 'website', 'vicinity', 'international_phone_number'],
        placeId: placeId
    };
}

function showInfoWindow(evt, service_marker, service, map, infowindow, place, g) {
    service.getDetails(getPlace(place.place_id), function (p, status) {
        if (status == g.maps.places.PlacesServiceStatus.OK) {
            infowindow.setContent('<div><strong>' + p.name + '</strong><br>' +
                p.formatted_address + '<br>' +
                p.international_phone_number + '<br>' +
                '<a href="' + p.website + '">' + p.website + '</a>'
                + '</div>');
            infowindow.open(map, service_marker);
        } else {
            infowindow.setContent("error: " + status);
            infowindow.open(map, service_marker);
        }
    });
}

class DskMap extends Component {

    onMapReady = (mapProps, map) => {
        this.fetchPlaces(map);
    };

    fetchPlaces(map) {
        if (this.props.place === undefined) {
            return;
        }
        const fields = 'place_id,name,geometry,formatted_address,formatted_phone_number,website,vicinity,international_phone_number';
        const {google} = this.props;

        const service = new google.maps.places.PlacesService(map);
        // const placeQueryArr = this.props.place.map(getPlace);
        // const placeQueryArr = ['ChIJPel9LYYckUcR5hE66IQdj1s'].map(getPlace);
        let infowindow = new google.maps.InfoWindow();
        this.props.place.forEach(i =>
            fetch('https://maps.googleapis.com/maps/api/geocode/json?place_id='
                + i +
                '&key=AIzaSyB99gEB4LRUMVJB2h5psJU4lm5Szkd0y1w')
                .then(results => {
                    return results.json();
                })
                .then(data => {
                    if (data.status === google.maps.places.PlacesServiceStatus.OK) {
                        // console.log(data.results[0]);
                        let place = data.results[0];
                        let marker = new google.maps.Marker({
                            map: map,
                            position: place.geometry.location
                        });
                        google.maps.event.addListener(marker, 'click', function (evt) {
                            showInfoWindow(evt, this, service, map, infowindow, place, google);
                        });
                    } else {
                        console.log('failed finding:');
                        console.log(data.re);
                    }
                })
        );

    }

    render() {
        if (!this.props.loaded) return <div>Loading...</div>;
        return (
            <>
                <MobileContainer>
                </MobileContainer>
                <DesktopContainer>
                    <div className={styles.mapContainer}>
                        <Map
                            google={this.props.google}
                            zoom={6.5}
                            style={mapStyles}
                            initialCenter={{lat: 51.0, lng: 9.1}}
                            onReady={this.onMapReady}
                        >
                        </Map>
                    </div>
                </DesktopContainer>
            </>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyB99gEB4LRUMVJB2h5psJU4lm5Szkd0y1w',
    language: 'de',
    region: 'de',
    libraries: ['places']
})(DskMap);

