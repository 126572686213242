import React, {Component} from 'react';
import DskMap from "./DskMap";
import {graphql, StaticQuery} from "gatsby";


class DskMapContainer extends Component {

    render() {
        return (
            <>
                <StaticQuery query={mapQuery}
                             render={data => {
                                 const items = data.allMarkdownRemark.edges.map(i => i.node.frontmatter.name);
                                 return (
                                     <DskMap place={items}/>
                                 );
                             }}
                />
            </>
        )
    }
}

export default DskMapContainer;

const mapQuery = graphql`
  query mapQuery {
  allMarkdownRemark(filter: {frontmatter: {type: {eq: "map"}}}) {
    edges {
      node {
        frontmatter {
          title
          name
        }
      }
    }
  }
}
`;